import React from "react";

import { useParams } from "react-router-dom";
import { apiGet } from "../../utils/api";
import { useState } from "react";
import { useEffect } from "react";
import ScrollToTop from "components/scroll-to-top";
import { Toast } from "components/toast";
import notfound from "../../assets/image/notfound.svg";

const DeliveryDetails = () => {
  ScrollToTop();
  const [handleError, setHandleError] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState();
  const getDeliveryData = async () => {
    const res = await apiGet(`delivery/${id}`).catch((err) => {
      setHandleError(true);
    });
    const data = await res.data;
    return data;
  };

  useEffect(() => {
    if (!data) {
      getDeliveryData().then((item) => {
        setData(item.delivery);
      });
    }
  }, [data]);

  return (
    <div className="container  mx-auto w-full px-20 py-20">
      <div className="w-full  text-center felx items-center justify-centers ">
        <p className="font-bold text-3xl">Delivery Status</p>
      </div>
      {!handleError ? (
        data && (
          <div className="flex flex-col w-full  justify-center mt-6  rounded-lg shadow-md">
            <div className="border w-full flex justify-center items-center h-24">
              <p className="font-semibold text-lg">Delivery Details</p>
            </div>
            <div className="">
              <div className="p-3  text-semibold text-lg shadow">
                Delivery To :
                <span className="ml-5  text-xl font-bold">{data.name}</span>
              </div>
              <div className="p-3 text-semibold text-lg shadow">
                Delivery Status :
                <span className="ml-5  text-xl font-bold">
                  {data.deliveryStatus}
                </span>
              </div>
              <div className="p-3 text-semibold text-lg shadow">
                Delivery Address :
                <span className=" text-lg ml-5 font-bold">
                  {data.deliveryAdress}
                </span>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="text-center mt-12">
          <p>No data found</p>
          <img src={notfound} className="w-1/4 mt-12" alt="" />
        </div>
      )}
    </div>
  );
};

export default DeliveryDetails;
