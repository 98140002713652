import React from "react";
import { useState } from "react";
import { apiPost } from "../../utils/api";
import PageHeader from "../../components/page-header";
import banner from "assets/banner/banner.png";
import ScrollToTop from "components/scroll-to-top";
import { useToast } from "components/toast";

const Consultation = () => {
  ScrollToTop();
  const { add } = useToast();
  const [inputs, setinputs] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    date: "",
    message: "",
  });

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("consultation/addConsultation", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      companyName: inputs.companyName,
      date: inputs.date,
      message: inputs.message,
    }).catch((err) => {
      add({
        type: "warning",
        message: err.message,
      });
    });

    const data = await res.data;

    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then(() => {
        add({
          type: "success",
          message:
            "Your information has been recorded!! We will contact you shortly.",
        });
      })
      .then(() =>
        setinputs({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          date: "",
          message: "",
        })
      );
  };
  return (
    <div>
      <div>
        <PageHeader banner={banner} pageName="Consultation" />
      </div>
      <div className=" mt-10">
        <section className="register-section ">
          <div className="auto-container">
            <div className=" py-[5%] px-[10%] lg:px-[20%] ">
              <div className=" w-full text-center">
                <p className=" text-2xl mb-4 font-bold text-base-color  uppercase">
                  Book for your consultation
                </p>
              </div>
              {/*Form Column*/}
              {/*  <div class="form-column column col-lg-8 col-md-8 col-sm-12 "> */}
              <div className="col-md-10">
                <div className="welcome_text">
                  <form
                    onSubmit={handleSubmit}
                    className=" flex flex-col gap-2"
                    method="post"
                  >
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="name"
                        value={inputs.name}
                        onChange={handleChange}
                        className="  appearance-none outline-none w-full text-black "
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="email"
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                        className="appearance-none outline-none w-full text-black"
                        placeholder="Email"
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="phone"
                        value={inputs.phone}
                        onChange={handleChange}
                        className="appearance-none outline-none w-full text-black"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="text"
                        name="companyName"
                        value={inputs.companyName}
                        onChange={handleChange}
                        className="appearance-none outline-none w-full text-black"
                        placeholder="Company Name "
                        required
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <input
                        type="date"
                        name="date"
                        value={inputs.date}
                        onChange={handleChange}
                        className="appearance-none outline-none w-full text-black"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="border border-base-color w-full p-3">
                      <textarea
                        name="message"
                        value={inputs.message}
                        onChange={handleChange}
                        className="appearance-none outline-none w-full text-black"
                        rows={4}
                        cols={150}
                        placeholder="Leave Message"
                      />
                    </div>
                    <div className="w-[100px]">
                      <button
                        type="submit"
                        className="p-4 text-white  font-semibold bg-base-color hover:bg-black hover:duration-300 "
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Consultation;
