import React, { useState } from "react";
import Banner from "../../assets/gif/newsteller.gif";
import { apiPost } from "../../utils/api";
import { useToast } from "components/toast";

const Newsletter = () => {
  const { add } = useToast();
  const [inputs, setinputs] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("newsteller/addNewsteller", {
      email: inputs.email,
    }).catch((err) =>
      add({
        type: "warning",
        message: err.message,
      })
    );

    const data = await res.data;

    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    sendRequest()
      .then(() => {
        add({
          type: "success",
          message:
            "Your information has been recorded!! we will contact you shortly",
        });
      })
      .then(() => setinputs({ email: "" }));
  };
  return (
    <div
      className=" p-12"
      style={{
        backgroundImage: `url(${Banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className=" flex flex-col lg:flex-row items-center justify-between ">
        <div className=" flex flex-col justify-center items-center  lg:w-1/2 lg:px-5">
          <p className=" font-medium text-4xl">Newsletter Subscribe</p>
          <p>Sign up today for hints, tips and the latest Updates.</p>
        </div>
        <div className=" mt-4 lg:mt-0  lg:w-1/2 w-full flex flex-col lg:flex-row gap-2">
          <div className="  lg:w-[70%] px-4 py-3 bg-white shadow-lg  ">
            <input
              type="text"
              className="appearance-none outline-none w-full"
              placeholder="Your email address*"
              onChange={handleChange}
              name="email"
              value={inputs.email}
            />
          </div>
          <div
            className=" w-full px-4 py-2 bg-base-color flex items-center justify-center text-white text-center lg:w-[160px] hover:bg-black hover:duration-500 hover:text-white cursor-pointer"
            onClick={handleSubmit}
          >
            Subscribe
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
