import PageHeader from "components/page-header";
import React, { useState } from "react";
import banner from "assets/banner/banner.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "components/scroll-to-top";

const DeliveryTracking = () => {
  const [trackingId, setTrackingId] = useState("");
  ScrollToTop();
  const navigate = useNavigate();
  const handleTracking = () => {
    if (trackingId !== "") {
      navigate(`/delivery-details/${trackingId}`);
    }
  };

  return (
    <div>
      <PageHeader pageName={"Delivery"} banner={banner} />
      <section className="register-section mt-6">
        <div className="auto-container">
          <div className="row clearfix justify-content-md-center">
            {/*Form Column*/}
            {/*  <div class="form-column column col-lg-8 col-md-8 col-sm-12 "> */}
            <div className="row">
              <div className="col-xs-12 text-center">
                <div className="">
                  <h2 className="font-semibold text-xl">
                    Get the fastest service{" "}
                  </h2>
                  <h2 className=" font-medium text-base">for your product</h2>
                  <div className=" mt-4">
                    <p className=" font-medium">
                      As a part of our vision, we extend our logistics ecosystem
                      by enabling network partners, such as franchisees, <br />
                      retail partners and delivery agents, to onboard their
                      physical assets and resources and participate in our
                      platform
                    </p>
                  </div>
                  <div className="">
                    <form action="">
                      <div className="mt-6">
                        <div className="  ">
                          <input
                            className=" p-4 border-2 border-gray-400 w-1/2 rounded-lg appearance-none mr-3"
                            type="text"
                            name="tracking_number"
                            placeholder="Enter your Tracking Number"
                            onChange={(e) => {
                              setTrackingId(e.target.value);
                            }}
                          />
                          <input
                            className="submit border-2 bg-blue-400 p-4 rounded-lg text-white"
                            type="submit"
                            defaultValue="Track your product"
                            onClick={handleTracking}
                          />
                        </div>
                        <div className=" col-md-4 col-sm-4 col-xs-12"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="sec-title">
      <h2>Login Now</h2>
  </div>
  

  <div class="styled-form login-form">
 <div class="card card-login mx-auto text-center " style="background-color:#13b5ea;"> 	

           <div class="card-body">
            <form action="" method="post">
                <div class="input-group form-group">
                   
                    <input type="text" name="name" class="form-control" placeholder="Name">
                </div>

                <div class="input-group form-group">
                   
                    <input type="text" name="password" class="form-control" placeholder="Password">
                </div>

                <div class="form-group">
                    <input type="submit" name="btn" value="Login" class="btn btn-outline-danger float-right login_btn">
                </div>

            </form>
        </div>
</div> */}
            {/*Case block*/}
            {/*   <form action="#" method="POST">
                <div class="agileinfo">
                </div>
                    <div class="agileinfo-row">
                        <div class="ferry ferry-from">
                            <label>Name</label>
                            <input type="text" name="Name" placeholder="" required="">
                        </div>
                        <div class="ferry ferry-from">
                            <label>E-mail</label>
                            <input type="email" name="email" placeholder="" required="">
                        </div>
                        <div class="ferry ferry-from">
                            <label>Order ID</label>
                            <input type="text" name="Name" placeholder="" required="">
                        </div>
                        
                            
                        <div class="clear"></div>
                    </div>
                <div class="wthree-text"> 
                    <div class="wthreesubmitaits">
                        <input type="submit" name="submit" value="Submit">
                    </div>  
                </div>
        </form> */}
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeliveryTracking;
