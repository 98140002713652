import React from "react";
import hero1 from "../../assets/image/hero-section-2.jpg";
import hero2 from "../../assets/image/section-3.jpg";

const HeroSection = () => {
  return (
    <div className=" p-[6%]">
      <div className=" flex flex-col lg:flex-row justify-between gap-4 shadow-xl w-full  p-8">
        <div className=" lg:w-1/2 ">
          <div>
            <p className="text-5xl text-base-color  text-start font-bold">
              <span className=" border-base-color text-base-color ">About</span>{" "}
              <span className="text-black"> our company</span>
            </p>
            <div className="  flex gap-2 justify-center lg:justify-start items-center mt-10">
              <p className="font-bold lg:font-extrabold text-6xl lg:text-6xl text-base-color">
                {8}
              </p>
              <p className=" text-lg lg:text-2xl font-bold text-gray-500">
                years of excellence in sales <br /> training solution.
              </p>
            </div>
          </div>
          <div className="mt-10">
            <p>
              Sheikh Tube Mills Ltd. is a prominent pipe manufacturing company
              in Bangladesh that has established itself as a leader in the
              industry. Since its inception in 2017, the company has
              consistently provided high-quality pipes to meet the diverse needs
              of its customers. As a leading pipe manufacturer, Sheikh Tube
              Mills Ltd. specializes in producing a wide range of pipes for
              various applications. The company's product portfolio includes ms
              and gi pipes, and other related products. These pipes find
              applications in industries such as construction, infrastructure
              development, agriculture, and engineering.
            </p>
          </div>
        </div>
        <div>
          <img src={hero1} alt="" />
        </div>
      </div>

      <div className=" flex flex-col lg:flex-row justify-between gap-6 shadow-2xl w-full  p-8 mt-16">
        <div className="w-full lg:w-1/2">
          <img src={hero2} alt="" />
        </div>
        <div className="w-full lg:w-1/2 ">
          <div>
            <p className=" text-5xl text-base-color  text-start font-bold">
              <span className=" border-base-color">Why </span>{" "}
              <span className="text-black"> choose us?</span>
            </p>
            <div className=" flex  gap-2 items-end lg:items-center mt-10">
              <p className="text-lg lg:text-2xl font-bold text-gray-500">
                WE believe in quality over selling
              </p>
            </div>
          </div>
          <div className="mt-10">
            <p>
              Why Choose Us? advanced manufacturing techniques and
              state-of-the-art machinery to ensure the production of durable and
              reliable pipes. Stringent quality checks are implemented at every
              stage of the manufacturing process, from raw material sourcing to
              the final product inspection. Sheikh Tube Mills Ltd. places great
              emphasis on customer satisfaction. The company strives to
              understand the unique requirements of its customers and provides
              tailored solutions to meet their specific needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
